import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
require("dotenv").config();
import "./assets/fonts/fonts.css"

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

Vue.prototype.$http = axios;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
